import React, { Component } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import { authenticate, getMyInfo } from "../../actions/auth";
import isEmpty from "../../validation/is-empty";

import HeaderMessage from "../HeaderMessage";
import Input from "../forms/InputWithoutLabel";

export class Auth extends Component {
  state = {
    isLoading: false,
    alert: false,
    message: "",
    domain: "",
    urlDomain: "",
  };

  onCloseError = () => {
    this.setState({
      alert: false,
      message: "",
    });
  };

  onLogin = (values) => {
    this.setState({
      isLoading: true,
    });
    authenticate(values.domain, values.username, values.password)
      .then((resp) => {
        if (resp.data.success) {
          getMyInfo(values.domain, resp.data.token).then((user) => {
            this.setUser(user.data);
            this.setSession(resp.data, values.domain);
            this.onRouteUser(user.data);
          });
        } else {
          this.setState({
            isLoading: false,
            alert: true,
            message: resp.data.message,
          });
        }
      })
      .catch(() => {
        this.setState({
          isLoading: false,
          alert: true,
          message:
            "Network Error, Check your internet connection and try again.",
        });
      });
  };

  onImpersonateUser = (domain, token) => {
    getMyInfo(domain, token).then((user) => {
      this.setUser(user.data);
      this.onRouteUser(user.data);
    });
  };

  onRouteUser = (user) => {
    // Try to obtain the redirect URL from the query string
    const params = new URLSearchParams(window.location.search);
    const redirectUrl = params.get("redirect");

    // Default redirection logic here based on user roles or preferences
    let defaultRedirectUrl = this.determineUserLandingPage(user);

    // Redirect the user to the original destination or to the default one
    window.location.href = redirectUrl
      ? decodeURIComponent(redirectUrl)
      : defaultRedirectUrl;
  };

  determineUserLandingPage = (user) => {
    switch (user.PID) {
      case "0":
        return "/userPortal/admin";
      case "1":
        return "/userPortal";
      case "2":
        return "/tech";
      case "3":
        if (user?.preferences?.landingPage === "advisorBoard") {
          return "/userPortal/ro/board";
        } else if (user?.preferences?.landingPage === "techBoard") {
          return "/userPortal/ro/techBoard";
        } else {
          return "/userPortal/ro/board";
        }

      case "4":
        console.log("User type not authorized.");
      case "5":
        return "/userPortal/admin";
      default:
        console.log("user type unknown");
    }
  };

  setUser = (user) => {
    localStorage.setItem("me", JSON.stringify(user));
  };

  setSession = (authResult, domain) => {
    // Set the time that the access token will expire at
    localStorage.setItem("token", authResult.token);
    localStorage.setItem("domain", domain);
    localStorage.setItem("refreshToken", authResult.refreshToken);
    localStorage.setItem("expires_at", authResult.exp);
  };

  refreshSession = () => {
    if (isEmpty(localStorage.domain)) {
      localStorage.clear();
    } else if (!isEmpty(localStorage.impToken)) {
      console.log("impersonation token present");
      this.onImpersonateUser(localStorage.domain, localStorage.impToken);
    } else if (!isEmpty(localStorage.me) && !isEmpty(localStorage.expires_at)) {
      console.log("me and expires at in LS");
      if (new Date().getTime() / 1000 < localStorage.expires_at) {
        console.log("token has not expired");
        let me = JSON.parse(localStorage.me);
        this.onRouteUser(me);
      }
    } else {
      // clear any stale DC info from local storage
      localStorage.clear();
    }
  };

  componentDidMount() {
    console.log("attempt to refresh session");
    const isAmplifyApp = /amplifyapp\.com$|ngrok-free\.app|ngrok\.io$/.test(
      location.hostname
    );  
    // eslint-disable-next-line no-restricted-globals
    let domain = location.hostname.split(".")[0];
    if (domain != "localhost" && domain != "app" && !isAmplifyApp) {
      // eslint-disable-next-line no-restricted-globals
      this.setState({ domain: domain });
    }

    this.refreshSession();
  }

  render() {
    return (
      <div className="background">
        <div className="container">
          <Formik
            enableReinitialize
            initialValues={{
              domain: this.state.domain,
              username: "",
              password: "",
            }}
            validationSchema={Yup.object().shape({
              domain: Yup.string().required("Site Domain is required."),
              username: Yup.string().required("Username is required."),
              password: Yup.string().required("Password is required."),
            })}
          >
            {({ errors, touched, values }) => (
              <Form className="form-signin">
                <div className="text-center">
                  <h1>Digital Concierge</h1>
                  <img
                    src="/images/DC-Logo-420x400.png"
                    width="250px"
                    alt="Digital Concierge Logo"
                  />
                  <p></p>
                </div>
                {this.state.alert && (
                  <HeaderMessage
                    type="danger"
                    message={this.state.message}
                    onCloseError={this.onCloseError}
                  />
                )}
                {this.state.domain == "" && (
                  <Input
                    name="domain"
                    placeholder="Site Domain"
                    touched={touched}
                    errors={errors}
                    overload={{
                      autoFocus: !this.state.domain,
                      autoCapitalize: "none",
                      autoCorrect: "off",
                    }}
                  />
                )}
                <Input
                  name="username"
                  placeholder="User Name"
                  touched={touched}
                  errors={errors}
                  overload={{
                    autoFocus: this.state.domain,
                    autoCapitalize: "none",
                    autoCorrect: "off",
                  }}
                />
                <Input
                  name="password"
                  placeholder="Password"
                  touched={touched}
                  errors={errors}
                  overload={{
                    type: "password",
                    autoCapitalize: "none",
                    autoCorrect: "off",
                  }}
                />
                <button
                  className="btn btn-lg btn-primary btn-block"
                  onClick={() => this.onLogin(values)}
                  disabled={
                    isEmpty(values.password) ||
                    isEmpty(values.username) ||
                    this.state.isLoading
                  }
                >
                  {this.state.isLoading ? "Authenticating..." : "Sign in"}
                </button>
                <br />
                <div className="text-center">
                  <a href="/forgot">Forgot Password</a>
                </div>
                <h5 className="text-center">
                  © {new Date().getFullYear()}{" "}
                  <a href="https://bdmenterprises.com">BDM Enterprises, Inc.</a>
                </h5>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

export default Auth;
